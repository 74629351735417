import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./orderPlace.css";
import { useIdleTimer } from "react-idle-timer";
import { Helmet } from "react-helmet-async";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { iconColor, iconStyles } from "../../../Components/StyledComponent/CommonStyles";
import { useStyles } from "../../../Components/OnlineStore/PhotoGallery/styled";
// or

const OnlineOrderPlace = ({ openOrderSuccessModal, orderId, setOpenOrderSuccessModal, setWillReload }) => {
	const handleOnIdle = (event) => {
		setOpenOrderSuccessModal(false);
		setWillReload(true);
	};

	useIdleTimer({
		timeout: 1000 * 5,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	return (
		<div>
			<Dialog
				open={openOrderSuccessModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{/* <DialogTitle id="alert-dialog-title">
					<Box></Box>
					<Box>
						<IconButton onClick={() => {}} sx={{ ...iconColor, ...iconStyles }} size="small">
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle> */}
				<DialogContent sx={{ height: 400, width: { sm: 300, md: 400 } }}>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
						<SvgIcon className="w4rAnimated_checkmark" sx={{ height: 100, width: 100 }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle
									className="path circle"
									fill="none"
									stroke="#1ea5e9"
									strokeWidth="6"
									strokeMiterlimit="10"
									cx="65.1"
									cy="65.1"
									r="62.1"
								/>
								<polyline
									className="path check"
									fill="none"
									stroke="#1ea5e9"
									strokeWidth="6"
									strokeLinecap="round"
									strokeMiterlimit="10"
									points="100.2,40.2 51.5,88.8 29.8,67.5 "
								/>
							</svg>
						</SvgIcon>
					</Box>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
						<DialogContentText variant="h5">Thank you for your order!</DialogContentText>
					</Box>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
						<Typography variant="body" align="center">
							We just send you an email with the details of <br /> your private PhotoPass Code.
						</Typography>
					</Box>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 1 }}>
						<Typography variant="body" align="center">
							Your order number {orderId ?? ""}
						</Typography>
					</Box>

					{/* <DialogContentText> */}
					{/* <div className="bg-booking"> */}
					{/* <Helmet>
							<title>Congratulations - Holipic</title>
						</Helmet> */}
					{/* <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}> */}
					{/* <div className="order-place-card text-center py-5">
							<div className="w4rAnimated_checkmark">
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
									<circle
										className="path circle"
										fill="none"
										stroke="#1ea5e9"
										strokeWidth="6"
										strokeMiterlimit="10"
										cx="65.1"
										cy="65.1"
										r="62.1"
									/>
									<polyline
										className="path check"
										fill="none"
										stroke="#1ea5e9"
										strokeWidth="6"
										strokeLinecap="round"
										strokeMiterlimit="10"
										points="100.2,40.2 51.5,88.8 29.8,67.5 "
									/>
								</svg>
							</div>
							<h2 className="my-4">Thank you for your order!</h2>
							<p>
								We just send you an email with the details of <br /> your private PhotoPass Code.
							</p>
							<p>Your order number {orderId}</p>
						</div> */}
					{/* </Container> */}
					{/* </div> */}
					{/* </DialogContentText> */}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default OnlineOrderPlace;
