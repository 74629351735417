import { lazy } from "react";
import { ROUTING_TREE } from "../constants/siteUrls";
import HomeLayout from "../Layouts/HomeLayout";
import Page404 from "../pages/Page404/Page404";
import ShopLayout from "../Layouts/ShopLayout";
import OnlineOrderPlace from "../pages/OnlineStore/OrderPlace/OrderPlace";
import { UserType } from "../utils/auth.utils";
import AuthProvider from "../utils/Providers/auth.providers";

const Booking = lazy(() => import("../Components/BookingPages/Booking/Booking"));
const BookingByBranch = lazy(() => import("../Components/BookingPages/Booking/BookingByBranch"));
const BookingDetails = lazy(() => import("../Components/BookingPages/BookingDetails/BookingDetails"));
const BookingCongratulations = lazy(() =>
	import("../Components/BookingPages/BookingCongratulations/BookingCongratulations"),
);
const BookingSchedule = lazy(() => import("../Components/BookingPages/BookingSchedule/BookingSchedule"));
const BookingList = lazy(() => import("../Components/BookingPages/Booking/BookingList/BookingList"));

const Home = lazy(() => import("../pages/Landing/Home"));
const Shop = lazy(() => import("../pages/Landing/Shop"));

// super admin
const SuperAdmin = lazy(() => import("../pages/SuperAdmin"));
const Dashboard = lazy(() => import("../pages/SuperAdmin/Dashboard"));
const Companies = lazy(() => import("../pages/SuperAdmin/Companies"));
const CompanyRequest = lazy(() => import("../pages/SuperAdmin/CompanyRequest"));
const ECommercePage = lazy(() => import("../pages/SuperAdmin/ECommercePage"));
const EWallet = lazy(() => import("../pages/SuperAdmin/EWallet"));
const Coupons = lazy(() => import("../pages/SuperAdmin/Coupons"));
const Currency = lazy(() => import("../pages/SuperAdmin/Currency"));
const Pricing = lazy(() => import("../pages/SuperAdmin/Pricing"));
const Cms = lazy(() => import("../pages/SuperAdmin/Cms"));

// company admin
const CompanyAdmin = lazy(() => import("../pages/CompanyAdmin"));
const CompanyDashboard = lazy(() => import("../pages/CompanyAdmin/Dashboard"));
const Branches = lazy(() => import("../pages/CompanyAdmin/Branches"));
const Photographers = lazy(() => import("../pages/CompanyAdmin/Photographers"));
const Customers = lazy(() => import("../pages/CompanyAdmin/Customers"));
const ManageOrders = lazy(() => import("../pages/CompanyAdmin/ManageOrders"));
const Orders = lazy(() => import("../pages/CompanyAdmin/Orders"));
const Bookings = lazy(() => import("../pages/CompanyAdmin/Bookings"));
const Archive = lazy(() => import("../pages/CompanyAdmin/Archive"));
const Activitylog = lazy(() => import("../pages/CompanyAdmin/Activitylog"));

// in store admin
const InStoreAdmin = lazy(() => import("../pages/InStoreAdmin"));
const InStoreDashboard = lazy(() => import("../pages/InStoreAdmin/Dashboard"));
const InStorePhotographers = lazy(() => import("../pages/InStoreAdmin/Photographers"));
const InStoreCustomers = lazy(() => import("../pages/InStoreAdmin/Customers"));
const InStoreManageOrders = lazy(() => import("../pages/InStoreAdmin/ManageOrders"));
const InStoreOrders = lazy(() => import("../pages/InStoreAdmin/Orders"));
const InStoreBookings = lazy(() => import("../pages/InStoreAdmin/Bookings"));
const InStoreArchive = lazy(() => import("../pages/InStoreAdmin/Archive"));
const InStoreSnapPhotos = lazy(() => import("../pages/InStoreAdmin/SnapPhotos"));
const CompanySnapPhotos = lazy(() => import("../pages/CompanyAdmin/SnapPhotos"));
const InStorePriceList = lazy(() => import("../pages/InStoreAdmin/PriceList"));
const InStoreBranchAdminLogin = lazy(() => import("../pages/InStoreAdmin/InStoreAuth/Login"));
const InStoreSellingLogin = lazy(() => import("../pages/InStoreSelling/InStoreSellingAuth/Login"));
const InStoreForgetPassword = lazy(() => import("../pages/InStoreAdmin/InStoreAuth/ForgetPassword"));

// in-store-selling
const InStoreSelling = lazy(() => import("../pages/InStoreSelling/SellingHome"));
const InStoreSellingPhotos = lazy(() => import("../pages/InStoreSellingPhotos"));
const OrderPlace = lazy(() => import("../pages/InStoreSellingPhotos/OrderPlace/OrderPlace"));

// online-store
const OnlineStoreLogin = lazy(() => import("../pages/OnlineStore/OnlineStoreAuth/Login"));
const OnlineStoreForgetPassCode = lazy(() => import("../pages/OnlineStore/OnlineStoreAuth/ForgetPassword"));
const OnlineStore = lazy(() => import("../pages/OnlineStore"));

const Login = lazy(() => import("../pages/Landing/Auth/Login"));
const ForgetPassword = lazy(() => import("../pages/Landing/Auth/ForgetPassword"));

const InStoreSellingArchive = lazy(() => import("../pages/InStoreSelling/Archive"));

const ResetPassword = lazy(() => import("../pages/Landing/Auth/ResetPassword"));
const VerifyAccount = lazy(() => import("../pages/Landing/Auth/VerifyAccount"));

const routes = [
	{
		element: <HomeLayout />,
		path: ROUTING_TREE.HOME.HOME,
		children: [{ path: "", element: <Home /> }],
	},
	// {
	// 	element: <ShopLayout />,
	// 	path: ROUTING_TREE.HOME.SHOP,
	// 	children: [{ path: "", element: <Shop /> }],
	// },
	// {
	// 	path: ROUTING_TREE.AUTH.AUTH,
	// 	children: [
	// 		{
	// 			path: ROUTING_TREE.AUTH.LOGIN,
	// 			element: (
	// 				<AuthProvider>
	// 					<Login />
	// 				</AuthProvider>
	// 			),
	// 		},
	// 		{
	// 			path: ROUTING_TREE.AUTH.FORGET_PASSWORD,
	// 			element: <ForgetPassword />,
	// 		},
	// 		{ path: ROUTING_TREE.AUTH.RESET_TOKEN, element: <ResetPassword /> },
	// 		{
	// 			path: ROUTING_TREE.AUTH.VERIFY_TOKEN,
	// 			element: <VerifyAccount />,
	// 		},
	// 	],
	// },
	// // in store auth
	// {
	// 	path: ROUTING_TREE.IN_STORE_AUTH.AUTH,
	// 	children: [
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_AUTH.SELLING_PAGE_LOGIN,
	// 			element: <InStoreSellingLogin />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN,
	// 			element: (
	// 				<AuthProvider>
	// 					<InStoreBranchAdminLogin />
	// 				</AuthProvider>
	// 			),
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_AUTH.FORGET_PASSWORD,
	// 			element: <InStoreForgetPassword />,
	// 		},
	// 	],
	// },
	// // super admin
	// {
	// 	element: (
	// 		<AuthProvider roles={UserType.SUPER_ADMIN}>
	// 			<SuperAdmin />
	// 		</AuthProvider>
	// 	),
	// 	path: ROUTING_TREE.SUPER_ADMIN.SUPER,
	// 	children: [
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.DASHBOARD, element: <Dashboard /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.COMPANIES, element: <Companies /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.COMPANY_REQUEST, element: <CompanyRequest /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.E_COMMERCE, element: <ECommercePage /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.E_WALLET, element: <EWallet /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.COUPONS, element: <Coupons /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.CURRENCY, element: <Currency /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.PRICING, element: <Pricing /> },
	// 		{ path: ROUTING_TREE.SUPER_ADMIN.CMS, element: <Cms /> },
	// 	],
	// },
	// // company admin
	// {
	// 	element: (
	// 		<AuthProvider roles={UserType.COMPANY_ADMIN}>
	// 			<CompanyAdmin />
	// 		</AuthProvider>
	// 	),
	// 	path: ROUTING_TREE.COMPANY_ADMIN.COMPANY,
	// 	children: [
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.DASHBOARD,
	// 			element: <CompanyDashboard />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.BRANCHES,
	// 			element: <Branches />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.PHOTOGRAPHERS,
	// 			element: <Photographers />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.CUSTOMERS,
	// 			element: <Customers />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.MANAGE_ORDERS,
	// 			element: <ManageOrders />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.ORDERS,
	// 			element: <Orders />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.BOOKINGS,
	// 			element: <Bookings />,
	// 		},
	// 		{ path: ROUTING_TREE.COMPANY_ADMIN.ARCHIVE, element: <Archive /> },
	// 		{
	// 			path: ROUTING_TREE.COMPANY_ADMIN.SNAP_PHOTOS,
	// 			element: <CompanySnapPhotos />,
	// 		},
	// 		{ path: ROUTING_TREE.COMPANY_ADMIN.ACTIVITY_LOG, element: <Activitylog /> },
	// 	],
	// },
	// // in-store admin
	// {
	// 	element: (
	// 		<AuthProvider roles={UserType.BRANCH_ADMIN}>
	// 			<InStoreAdmin />
	// 		</AuthProvider>
	// 	),
	// 	path: ROUTING_TREE.IN_STORE_ADMIN.IN_STORE,
	// 	children: [
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.DASHBOARD,
	// 			element: <InStoreDashboard />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.PHOTOGRAPHERS,
	// 			element: <InStorePhotographers />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.CUSTOMERS,
	// 			element: <InStoreCustomers />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.MANAGE_ORDERS,
	// 			element: <InStoreManageOrders />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.ORDERS,
	// 			element: <InStoreOrders />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.BOOKINGS,
	// 			element: <InStoreBookings />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.ARCHIVE,
	// 			element: <InStoreArchive />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.SNAP_PHOTOS,
	// 			element: <InStoreSnapPhotos />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_ADMIN.PRICE_LIST,
	// 			element: <InStorePriceList />,
	// 		},
	// 	],
	// },

	// // in-store-selling temporary
	// {
	// 	path: ROUTING_TREE.IN_STORE_SELLING.IN_STORE_SELLING,
	// 	children: [
	// 		{ path: "", element: <InStoreSelling /> },
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_SELLING.PHOTO_PASS,
	// 			element: (
	// 				// <AuthProvider roles={UserType.}>
	// 				<InStoreSellingPhotos />
	// 				// </AuthProvider>
	// 			),
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_SELLING.ORDER_PLACE,
	// 			element: <OrderPlace />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_SELLING.ARCHIVE,
	// 			element: <InStoreSellingArchive />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.IN_STORE_SELLING.SNAP_PHOTOS,
	// 			element: <InStoreSnapPhotos />,
	// 		},
	// 	],
	// },
	// // online-store
	// {
	// 	path: "/online-store",
	// 	children: [
	// 		{ path: "login", element: <OnlineStoreLogin /> },
	// 		{
	// 			path: "gallery",
	// 			element: <OnlineStore />,
	// 		},
	// 		{
	// 			path: "success",
	// 			element: <OnlineOrderPlace />,
	// 		},
	// 		{
	// 			path: "forget-password",
	// 			element: <OnlineStoreForgetPassCode />,
	// 		},
	// 	],
	// },
	// bookings
	{
		path: ROUTING_TREE.BOOKINGS.BOOKINGS,
		children: [
			{ path: "", element: <Booking /> },
			{
				path: ROUTING_TREE.BOOKINGS.BOOKINGS_BY_BRANCH_ID,
				element: <BookingByBranch />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.DETAILS,
				element: <BookingDetails />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.SCHEDULE,
				element: <BookingSchedule />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.CONGRATULATIONS,
				element: <BookingCongratulations />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.LISTS,
				element: (
					// <AuthProvider roles={UserType.SUPER_ADMIN}>
					<BookingList />
					// </AuthProvider>
				),
			},
		],
	},
	// spa-bookings
	// {
	// 	path: ROUTING_TREE.SPA_BOOKINGS.BOOKINGS,
	// 	children: [
	// 		{ path: "", element: <Booking /> },
	// 		{
	// 			path: ROUTING_TREE.SPA_BOOKINGS.BOOKINGS_BY_BRANCH_ID,
	// 			element: <BookingByBranch />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.SPA_BOOKINGS.DETAILS,
	// 			element: <BookingDetails />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.SPA_BOOKINGS.SCHEDULE,
	// 			element: <BookingSchedule />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.SPA_BOOKINGS.CONGRATULATIONS,
	// 			element: <BookingCongratulations />,
	// 		},
	// 		{
	// 			path: ROUTING_TREE.SPA_BOOKINGS.LISTS,
	// 			element: (
	// 				// <AuthProvider roles={UserType.SUPER_ADMIN}>
	// 				<BookingList />
	// 				// </AuthProvider>
	// 			),
	// 		},
	// 	],
	// },

	// not found page
	{
		path: "*",
		element: <Page404 />,
	},
];

export const routesPWA = [
	{
		path: "/",
		children: [
			{ path: "", element: <Booking /> },
			{
				path: ROUTING_TREE.BOOKINGS.DETAILS,
				element: <BookingDetails />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.SCHEDULE,
				element: <BookingSchedule />,
			},
			{
				path: ROUTING_TREE.BOOKINGS.CONGRATULATIONS,
				element: <BookingCongratulations />,
			},
			{ path: ROUTING_TREE.BOOKINGS.LISTS, element: <BookingList /> },
		],
	},
];

export default routes;
