import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
	cardAvatar: {
		borderRadius: "0 !important",
		height: "100% !important",
		width: "100% !important",
		maxHeight: "100% !important",
		// width: "100% !important",

		resizeMode: "contain",
		objectFit: "contain",
		background: "#f2f2f2 !important",
		"& > img": {
			objectFit: "cover",
		},
		// height: "162px",
		[theme.breakpoints.up("xl")]: {
			height: "200px !important",
		},
		[theme.breakpoints.down("xl")]: {
			height: "120px !important",
		},
		[theme.breakpoints.down("lg")]: {
			height: "130px !important",
		},
		[theme.breakpoints.down("md")]: {
			height: "160px !important",
		},
		[theme.breakpoints.down("sm")]: {
			height: "112px !important",
		},
	},
	cardAvatarContain: {
		borderRadius: "0 !important",
		height: "100% !important",
		maxHeight: "100% !important",
		width: "100% !important",
		margin: "0 auto",
		"& > img": {
			objectFit: "contain",
		},
		[theme.breakpoints.up("xl")]: {
			height: "200px !important",
		},
		[theme.breakpoints.down("xl")]: {
			height: "120px !important",
		},
		[theme.breakpoints.down("lg")]: {
			height: "130px !important",
		},
		[theme.breakpoints.down("md")]: {
			height: "160px !important",
		},
		[theme.breakpoints.down("sm")]: {
			height: "112px !important",
		},
	},
	cardContent: {
		background: "#f2f2f2 !important",
		padding: "0 !important",
		// height: "153px !important",
	},
	cardActions: {
		padding: "0 !important",
		height: "40px !important",
	},

	cardDesc: {
		padding: "0.55rem 0",
		borderTop: "1px solid #f1f1f1",
		"&:last-child": {
			borderBottom: "1px solid #f1f1f1",
		},
	},

	cancelBtn: {
		position: "absolute !important",
		top: 0,
		right: 0,
	},
	orderBtn: {
		display: "flex",
		gap: 5,
		paddingLeft: "10px !important",
		paddingRight: "10px !important",
		borderRadius: "30px !important",
		color: "#fff !important",
		background: "linear-gradient(25deg, #00a2ea, #85d0f4) !important",
	},

	floatIconButton: {
		background: "#fff !important",
		color: "#06A4EC !important",
	},

	menuRoot: {
		"& .MuiButton-root": {
			display: "flex !important",
			flexDirection: "column",
			color: "#5f6368 !important",
			textTransform: "uppercase !important",
		},
	},
	filterMenuRoot: {
		"& .MuiButton-root": {
			color: "#5f6368 !important",
			textTransform: "uppercase !important",
		},
	},

	// checkout
	checkoutModal: {
		marginLeft: "10px",
		marginRight: "10px",
		"& .MuiPaper-root": {
			minWidth: "800px !important",
			margin: "8px !important",
			[theme.breakpoints.down("md")]: {
				minWidth: "100% !important",
			},
		},
		"& .MuiDialogContent-root": {
			padding: "0 !important",
		},
	},

	checkoutTable: {
		"& .MuiTableCell-root": { padding: "0 !important" },
		"& .MuiTableHead-root": {
			padding: "0 !important",
			"& .MuiTableCell-root": {
				color: theme.palette.primary.main,
				fontSize: 18,
			},
		},
		"& th": {
			position: "sticky",
			top: "0",
		},
		"& .MuiTableBody-root": {
			padding: "0 !important",
			"& .MuiAvatar-root": {
				borderRadius: "0 !important",
				height: "auto !important",
				width: "70px",
				textAlign: "center",
			},
		},
	},

	checkoutDialogActions: {
		justifyContent: "space-between !important",
		background: "linear-gradient(25deg, #00a2ea, #85d0f4)",

		"& .MuiTypography-root": {
			fontSize: 18,
		},
		"& .MuiTypography-root, & svg": {
			color: "#fff",
		},

		"& .MuiBox-root": {
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			gap: 10,
		},
	},

	// payment
	paymentModal: {
		"& .MuiPaper-root": {
			minWidth: "450px !important",
			maxWidth: "100% !important",
			[theme.breakpoints.down("sm")]: {
				minWidth: "95% !important",
				margin: "8px",
			},
		},
		"& .MuiDialogContent-root": {
			padding: "5px !important",
			background: "#f3f3f3",
		},
	},

	paymentDialogTitle: {
		padding: "8px !important",
		display: "flex !important",
		alignItems: "center !important",
		justifyContent: "space-between !important",
		gap: 5,
		borderBottom: "2px solid #ddd",
	},

	paymentDialogActions: {
		minHeight: "50px !important",
		cursor: "pointer !important",
		justifyContent: "space-between !important",
		background: "linear-gradient(25deg, #00a2ea, #85d0f4)",
		"&:hover": {
			background: "linear-gradient(25deg, #00a2ea, #00a2ea)",
		},

		"& .MuiTypography-root, & svg": {
			textTransform: "uppercase",
			color: "#fff",
		},
	},

	paymentCardAvatar: {
		borderRadius: "0 !important",
	},

	// photo preview
	photoPreview: {
		"& .MuiPaper-root": {
			margin: "8px !important",
			borderRadius: "0",
		},

		"& .MuiDialogContent-root": {
			padding: "15px 15px 0 15px !important",
			[theme.breakpoints.down("sm")]: {
				padding: "0 !important",
			},

			"& .MuiTypography-h5": {
				marginTop: 20,
				marginBottom: 20,
				textAlign: "center",
				lineHeight: "1.3",
				fontWeight: 600,
			},
		},
	},

	previewImg: {
		"& .MuiAvatar-root": {
			position: "relative !important",
			borderRadius: "0 !important",
			height: "380px !important",
			width: "100% !important",

			[theme.breakpoints.down("sm")]: {
				height: "250px !important",
			},
		},

		"& .MuiTypography-root": {
			position: "absolute",
			minWidth: "80%",
			top: "63%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			color: "#fff",
			border: "3px solid #fff",
			padding: "5px",
			textTransform: "uppercase",
			textAlign: "center",

			[theme.breakpoints.down("sm")]: {
				top: "55%",
			},
		},
	},

	dialogActions: {
		"& .MuiButton-root": {
			fontSize: "18px !important",
		},
		"& .MuiButton-root:first-child": {
			padding: "3px !important",
			borderRadius: "2px !important",
			"&:hover": {
				background: "linear-gradient(25deg, #00a2ea, #85d0f4) !important",
				color: "#fff !important",
			},
		},
		"& .MuiButton-root:last-child": {
			padding: "3px !important",
			borderRadius: "2px !important",
			color: "#fff !important",
			background: "linear-gradient(25deg, #00a2ea, #85d0f4) !important",
		},
	},

	// coupon modal
	couponDialogRoot: {
		"& .MuiPaper-root": {
			maxWidth: 400,
			margin: "8px !important",
			borderRadius: 10,
		},

		"& .MuiDialogTitle-root": {
			background: "#f2f2f2",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 0,
		},

		"& .MuiDialogContent-root": {
			textAlign: "center",
			padding: "15px !important",
		},
		"& .MuiDialogActions-root": {
			padding: 0,
			borderTop: `1px solid ${theme.palette.divider}`,

			"& .MuiButton-root": {
				fontSize: "22px !important",
				fontWeight: "500 !important",
			},
		},
	},

	// account expiry
	accountExpiryDialogRoot: {
		"& .MuiPaper-root": {
			maxWidth: 400,
			margin: "8px !important",
			borderRadius: 10,
		},

		"& .MuiDialogTitle-root": {
			background: "#f2f2f2",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 0,
		},

		"& .MuiDialogContent-root": {
			textAlign: "center",
			padding: "15px !important",
		},
	},
	expiryDate: {
		fontSize: "11rem !important",
		lineHeight: "1 !important",
	},

	// feedback
	feedBackDialogRoot: {
		"& .MuiPaper-root": {
			maxWidth: 400,
			margin: "8px !important",
			borderRadius: 10,
		},

		"& .MuiDialogTitle-root": {
			background: "#f2f2f2",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 0,
		},

		"& .MuiDialogContent-root": {
			textAlign: "center",
			padding: "15px !important",

			"& .MuiFormControl-root": {
				"& textarea": {
					border: "none !important",
				},
			},
		},

		"& .MuiDialogActions-root": {
			padding: 0,
			borderTop: `1px solid ${theme.palette.divider}`,

			"& .MuiButton-root": {
				fontSize: "22px !important",
				fontWeight: "500 !important",
			},
		},
	},

	// float actions
	floatActionsTop: {
		padding: "0.56rem",
		background: "linear-gradient(25deg, #00a2ea, #85d0f4) !important",

		"& .MuiTypography-root, & svg": {
			fontWeight: 500,
			color: "#fff !important",
		},
	},
	floatActionsBottom: {
		padding: "0.56rem",
		display: "flex",
	},

	// photo zoom
	// photoZoom: {
	// 	"& .MuiPaper-root": {
	// 		maxWidth: "800px",
	// 		maxHeight: "75%",
	// 		margin: "8px !important",
	// 		borderRadius: "0",
	// 	},

	// 	"& .MuiDialogContent-root": {
	// 		position: "relative !important",
	// 		padding: "0 !important",
	// 	},
	// },
	// selectImgAction: {
	// 	position: "absolute",
	// 	top: 8,
	// 	right: 8,

	// 	"& .MuiIconButton-root": {
	// 		background: "#000",
	// 		"& .MuiSvgIcon-root": {
	// 			color: "#fff",
	// 		},
	// 	},
	// },

	// photoZoomSwiper: {
	// 	"& .swiper-wrapper": {
	// 		// display: "flex !important",
	// 		// alignItems: "center !important",
	// 	},

	// 	"& .swiper-slide": {
	// 		"& .MuiAvatar-root": {
	// 			borderRadius: "0 !important",
	// 			height: "auto !important",
	// 			width: "100% !important",
	// 		},
	// 	},
	// 	"& .swiper-button-prev, & .swiper-button-next": {
	// 		height: "30px",
	// 		width: "30px",
	// 		background: "#000",
	// 		borderRadius: "100%",

	// 		"&::after": {
	// 			fontSize: "1.2rem !important",
	// 			color: "#fff",
	// 		},
	// 	},
	// },
}));
