import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useStyles } from "./styled";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ROUTING_TREE } from "../../../../constants/siteUrls";
import { NavHashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../../../../utils/helper/scrollWithOffset";

const languages = ["Deutsch", "English", "Francais", "Italiano", "Turkce", "Pyccknn"];

const MobileMenuDemo = ({ mobileMenuOpen, handleToggleMobileMenuOpen, handleSignUpDialogOpen }) => {
	const classes = useStyles();
	return (
		<div>
			<React.Fragment>
				<SwipeableDrawer
					anchor="right"
					open={mobileMenuOpen}
					onClose={() => handleToggleMobileMenuOpen(false)}
					onOpen={() => handleToggleMobileMenuOpen(true)}
				>
					<Box sx={{ width: { xs: 300, sm: 400 } }}>
						<Box textAlign="right" sx={{ m: 1 }}>
							<IconButton onClick={() => handleToggleMobileMenuOpen(false)} sx={{ color: "#5f6368" }}>
								<CloseIcon fontSize="large" />
							</IconButton>
						</Box>
						<Divider />

						{/* menu */}
						<Box px={2}>
							<List className={classes.mobileMenuList}>
								<ListItem>
									<NavHashLink
										onClick={() => handleToggleMobileMenuOpen(false)}
										to="#home"
										smooth
										activeclassname="selected"
										className={classes.link}
									>
										<ListItemText>Home</ListItemText>
									</NavHashLink>
								</ListItem>
								<ListItem>
									<NavHashLink
										onClick={() => handleToggleMobileMenuOpen(false)}
										to="#benefits"
										smooth
										activeclassname="selected"
										scroll={(el) => scrollWithOffset(el, 50)}
										className={classes.link}
									>
										<ListItemText>Benefits</ListItemText>
									</NavHashLink>
								</ListItem>
								<ListItem>
									<NavHashLink
										onClick={() => handleToggleMobileMenuOpen(false)}
										to="#work"
										smooth
										activeclassname="selected"
										scroll={(el) => scrollWithOffset(el, 50)}
										className={classes.link}
									>
										<ListItemText>How It Work</ListItemText>
									</NavHashLink>
								</ListItem>
								<ListItem>
									<Box className={classes.expandMenu}>
										<Accordion>
											<AccordionSummary
												expandIcon={<ArrowDropDownIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography>Apps</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<List>
													<Link to="/" closeMobileMenu className={classes.link}>
														<ListItem>
															<NavHashLink
																onClick={() => handleToggleMobileMenuOpen(false)}
																to="#cameraApp"
																smooth
																activeclassname="selected"
																scroll={(el) => scrollWithOffset(el, 50)}
																className={classes.link}
															>
																<ListItemText>Camera App</ListItemText>
															</NavHashLink>
														</ListItem>
													</Link>
													<ListItem>
														<NavHashLink
															onClick={() => handleToggleMobileMenuOpen(false)}
															to="#instoreApp"
															smooth
															activeclassname="selected"
															scroll={(el) => scrollWithOffset(el, 50)}
															className={classes.link}
														>
															<ListItemText>In-Store App</ListItemText>
														</NavHashLink>
													</ListItem>
												</List>
											</AccordionDetails>
										</Accordion>
									</Box>
								</ListItem>
								<ListItem>
									<NavHashLink
										onClick={() => handleToggleMobileMenuOpen(false)}
										to="#pricing"
										smooth
										activeclassname="selected"
										scroll={(el) => scrollWithOffset(el, 50)}
										className={classes.link}
									>
										<ListItemText>Pricing</ListItemText>
									</NavHashLink>
								</ListItem>
								<ListItem onClick={handleSignUpDialogOpen}>
									<ListItemText>Register</ListItemText>
								</ListItem>
								<ListItem>
									<Box className={classes.expandMenu}>
										<Accordion>
											<AccordionSummary
												expandIcon={<ArrowDropDownIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography>En</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<List>
													{languages.map((lan, id) => (
														<Link key={id} to="/" closeMobileMenu className={classes.link}>
															<ListItem>
																<ListItemText>{lan}</ListItemText>
															</ListItem>
														</Link>
													))}
												</List>
											</AccordionDetails>
										</Accordion>
									</Box>
								</ListItem>
								<Link to={ROUTING_TREE.HOME.SHOP} className={classes.link}>
									<ListItem>
										<ListItemText>Shop</ListItemText>
									</ListItem>
								</Link>
							</List>
						</Box>
					</Box>
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	);
};

export default MobileMenuDemo;
